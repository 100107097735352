<template>
  <div class="h-100">
    <SocialsPrompt
      v-if="showPrompt"
      :surveyStep="surveyStep"
      :surveyStepDuration="surveyStepDuration"
      @continue="nextSurveyStep"
    />
    <SocialsSpinner v-if="showSpinner" />
    <transition :name="'fade'">
      <div v-if="!showPrompt && !showSpinner" class="tiktok-container h-100">
        <Header class="header" />
        <PostList class="post-list" />
        <Footer class="footer" />
      </div>
    </transition>
  </div>
</template>

<script>
import Header from "../components/Tiktok/Mobile/Header.vue";
import PostList from "../components/Tiktok/Mobile/PostList.vue";
import Footer from "../components/Tiktok/Mobile/Footer.vue";
import SocialsPrompt from "../components/SocialsPrompt.vue";
import SocialsSpinner from "../components/SocialsSpinner.vue";

import { onNavigationBackToSetup } from "../mixins/instagramFacebookViewers";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Tiktok",
  emits: ["finishedRecording"],
  components: {
    Header,
    PostList,
    Footer,
    SocialsPrompt,
    SocialsSpinner,
  },
  mixins: [onNavigationBackToSetup],
  data() {
    return {
      showSpinner: false,
    };
  },
  created() {
    this.$store.commit("tiktok/RESET_STATE");
    this.verifySupportedDevice();
  },
  computed: {
    ...mapGetters("stimulus", {
      endLinks: "getEndLinks",
    }),
    ...mapGetters("tiktok", {
      showPrompt: "getShowPrompt",
      surveyStep: "getSurveyStep",
      surveyStepDuration: "getSurveyStepDuration",
    }),
  },
  methods: {
    ...mapActions("stimulus", ["setSocialsFirstSurveyStepImgCount"]),
    ...mapActions("tiktok", ["getPosts"]),
    ...mapMutations("tiktok", ["SET_SHOW_PROMPT", "SET_SURVEY_STEP"]),
    onQuit() {
      this.$store.commit("stimulus/SET_PERMISSION", false);
      if (this.endLinks.screenout) window.location = this.endLinks.screenout;
    },
    verifySupportedDevice() {
      const mobileDevice = navigator.userAgent.toLowerCase().indexOf("mob");
      if (mobileDevice === -1) this.SET_SURVEY_STEP("unsupported");
    },
    nextSurveyStep() {
      if (this.surveyStep === "unsupported") {
        this.onQuit();
      } else if (this.surveyStep === "scroll") {
        this.SET_SHOW_PROMPT(false);
        this.showSpinner = true;
        // fetch posts while showing spinner
        this.getPosts();
        this.spinnerInterval = setInterval(() => {
          this.showSpinner = false;
          clearInterval(this.spinnerInterval);
        }, 2000);
      } else if (this.surveyStep === "stimulus") {
        this.setSocialsFirstSurveyStepImgCount();
        this.SET_SHOW_PROMPT(false);
      } else if (this.surveyStep === "finish") {
        // dont hide prompt and use as background for the loader modal while uploading assets
        this.$emit("finishedRecording");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/tiktok-styles.scss";
.tiktok-container::v-deep * {
  // Change default font family for all tiktok subcomponents
  font-family: $font-family-primary;
}
.tiktok-container {
  background-color: black;
  color: white;
}
.header {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 44px;
}
.post-list {
  height: calc(100% - #{$footer-mobile-height});
}
.footer {
  height: $footer-mobile-height;
}
</style>
