<template>
  <b-row
    class="header-container justify-content-between align-items-center no-gutters"
  >
    <b-col class="col-auto"
      ><svg
        fill="currentColor"
        font-size="24px"
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
      >
        <path
          d="M5 11a1 1 0 0 1 1-1h36a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-2Zm0 12a1 1 0 0 1 1-1h36a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-2Zm1 11a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h36a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H6Z"
        ></path></svg
    ></b-col>
    <b-col class="col-auto d-flex">
      <div class="pr-2 following">{{ $t("page.tiktok.following") }}</div>
      <div class="pl-2 font-weight-bold for-you">
        <span>
          {{ $t("page.tiktok.forYou") }}
        </span>
        <div class="for-you-underline"></div>
      </div>
    </b-col>
    <b-col class="col-auto">
      <svg
        class="magnifier-icon"
        width="35"
        height="35"
        viewBox="0 0 48 48"
        fill="#fff"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.7764 10.5C16.1265 10.5 11.5 15.172 11.5 20.993C11.5 26.814 16.1265 31.486 21.7764 31.486C24.5005 31.486 26.9782 30.4054 28.8221 28.6314C30.8097 26.7191 32.0527 24.0079 32.0527 20.993C32.0527 15.172 27.4262 10.5 21.7764 10.5ZM8.5 20.993C8.5 13.5669 14.4184 7.5 21.7764 7.5C29.1344 7.5 35.0527 13.5669 35.0527 20.993C35.0527 24.2993 33.881 27.3328 31.9342 29.6817L38.3696 36.2355C38.7566 36.6296 38.7508 37.2627 38.3568 37.6497L37.6432 38.3503C37.2492 38.7373 36.616 38.7315 36.2291 38.3374L29.7742 31.7638C27.5523 33.4701 24.7814 34.486 21.7764 34.486C14.4184 34.486 8.5 28.4191 8.5 20.993Z"
        ></path>
      </svg>
    </b-col>
  </b-row>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.header-container {
  font-size: 17px;
  box-sizing: content-box;
  padding: 0px 12px 38px;
  z-index: 5;
  background: linear-gradient(rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0));
}
.live-icon {
  height: 24px;
}
.magnifier-icon {
  height: 32px;
}
.following {
  color: rgba(255, 255, 255, 0.5) !important;
}
.for-you {
  display: flex;
  flex-direction: column;
}
.for-you-underline {
  background-color: white;
  height: 2px;
  width: 24px;
  align-self: center;
  border-radius: 0.5px;
  position: relative;
  top: 8px;
}
</style>
