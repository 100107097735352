<template>
  <div>
    <div class="swiper" ref="swiper">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(post, index) in customPosts"
          :key="index"
        >
          <PostItem
            :key="index"
            :post="post"
            :currentSlideId="currentSlideId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/swiper-bundle.css";

import PostItem from "./PostItem.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: { PostItem },
  data() {
    return {
      swiper: null,
      currentSlideId: 0,
    };
  },
  created() {
    this.$store.commit("utilities/SET_EMOTIVA_HEADER", false);
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.swiper, {
      direction: "vertical",
    });
    if (this.surveyStep === "scroll") this.firstSurveyStepEntry();
  },
  beforeDestroy() {
    this.resetAll();
  },
  destroyed() {
    this.$store.commit("utilities/SET_EMOTIVA_HEADER", true);
  },
  computed: {
    ...mapGetters("stimulus", {
      stimulus: "getStimulus",
    }),
    ...mapGetters("tiktok", {
      posts: "getPosts",
      surveyStep: "getSurveyStep",
      surveyStepDuration: "getSurveyStepDuration",
    }),
    customPosts() {
      if (this.surveyStep === "scroll") {
        return this.posts;
      } else {
        return this.posts.filter((post) => {
          return post.isStimulus;
        });
      }
    },
  },
  methods: {
    ...mapActions("tiktok", ["getPosts"]),
    ...mapMutations("tiktok", ["SET_SURVEY_STEP", "SET_SHOW_PROMPT"]),
    onSlideChange() {
      this.currentSlideId = this.swiper.realIndex;
      // fetch more posts when less than 2 are still remaining
      if (this.posts.length - this.swiper.realIndex <= 2) {
        this.getPosts().then(() => {
          this.swiper.update();
        });
      }
    },
    firstSurveyStepEntry() {
      this.swiper.on("slideChangeTransitionEnd", this.onSlideChange);
      this.$store.dispatch("stimulus/resetStimulusImages", {
        stimulusToken: this.stimulus[0].stimulusId,
      });
      this.firstSurveyStepTimeout = setTimeout(() => {
        this.SET_SURVEY_STEP("stimulus");
        this.SET_SHOW_PROMPT(true);
      }, this.surveyStepDuration * 1000);
    },
    resetAll() {
      if (this.firstSurveyStepTimeout)
        clearTimeout(this.firstSurveyStepTimeout);
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  width: 100%;
  height: 100%;
}
</style>
