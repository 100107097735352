<template>
  <div>
    <b-row class="no-gutters align-items-center h-100 footer-container">
      <b-col class="text-center">
        <span>
          <svg
            class="footer-icon"
            width="35"
            height="35"
            viewBox="0 0 48 48"
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M24.9505 7.84001C24.3975 7.38666 23.6014 7.38666 23.0485 7.84003L6.94846 21.04C6.45839 21.4418 6.2737 22.1083 6.48706 22.705C6.70041 23.3017 7.26576 23.7 7.89949 23.7H10.2311L11.4232 36.7278C11.5409 38.0149 12.6203 39 13.9128 39H21.5C22.0523 39 22.5 38.5523 22.5 38V28.3153C22.5 27.763 22.9477 27.3153 23.5 27.3153H24.5C25.0523 27.3153 25.5 27.763 25.5 28.3153V38C25.5 38.5523 25.9477 39 26.5 39H34.0874C35.3798 39 36.4592 38.0149 36.577 36.7278L37.7691 23.7H40.1001C40.7338 23.7 41.2992 23.3017 41.5125 22.705C41.7259 22.1082 41.5412 21.4418 41.0511 21.04L24.9505 7.84001Z"
            ></path>
          </svg>
        </span>
        <div class="footer-text font-weight-bold">
          {{ $t("page.tiktok.home") }}
        </div>
      </b-col>
      <b-col class="text-center"
        ><svg
          class="footer-icon"
          width="40"
          height="40"
          viewBox="0 0 48 48"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          style="flex: 0 0 40px"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18 12.5C15.5897 12.5 13.5849 14.5018 13.5849 17.0345C13.5849 19.5672 15.5897 21.569 18 21.569C20.4103 21.569 22.4151 19.5672 22.4151 17.0345C22.4151 14.5018 20.4103 12.5 18 12.5ZM10.5849 17.0345C10.5849 12.9017 13.8766 9.5 18 9.5C22.1234 9.5 25.4151 12.9017 25.4151 17.0345C25.4151 21.1673 22.1234 24.569 18 24.569C13.8766 24.569 10.5849 21.1673 10.5849 17.0345ZM18 29.8793C14.0801 29.8793 10.7403 32.5616 9.69697 36.2673C9.5473 36.7989 9.03833 37.1708 8.49337 37.0811L7.50662 36.9189C6.96166 36.8292 6.58837 36.3131 6.72325 35.7776C8.00732 30.6788 12.5509 26.8793 18 26.8793C23.449 26.8793 27.9927 30.6788 29.2767 35.7776C29.4116 36.3131 29.0383 36.8292 28.4934 36.9189L27.5066 37.0811C26.9617 37.1708 26.4527 36.7989 26.303 36.2673C25.2597 32.5616 21.9199 29.8793 18 29.8793Z"
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M33 31.5371C32.2445 31.5371 31.5198 31.668 30.8447 31.9093C30.3246 32.0951 29.7189 31.9243 29.4549 31.4392L28.9769 30.5608C28.713 30.0757 28.8907 29.463 29.4009 29.2516C30.513 28.791 31.7285 28.5371 33 28.5371C37.4554 28.5371 41.1594 31.6303 42.2706 35.7812C42.4135 36.3147 42.0386 36.8308 41.4935 36.9196L40.5065 37.0804C39.9614 37.1692 39.4546 36.7956 39.2894 36.2686C38.4217 33.5 35.91 31.5371 33 31.5371Z"
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M33 18.5C31.6193 18.5 30.5 19.6193 30.5 21C30.5 22.3807 31.6193 23.5 33 23.5C34.3807 23.5 35.5 22.3807 35.5 21C35.5 19.6193 34.3807 18.5 33 18.5ZM27.5 21C27.5 17.9624 29.9624 15.5 33 15.5C36.0376 15.5 38.5 17.9624 38.5 21C38.5 24.0376 36.0376 26.5 33 26.5C29.9624 26.5 27.5 24.0376 27.5 21Z"
          ></path>
        </svg>
        <div class="footer-text">{{ $t("page.tiktok.friends") }}</div>
      </b-col>
      <b-col class="text-center"
        ><svg
          class="SVGInline-svg"
          width="75"
          height="49"
          viewBox="0 0 75 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.01"
            x="0.25"
            y="0.25"
            width="74.5"
            height="48.5"
            fill="#D8D8D8"
            stroke="#979797"
            stroke-width="0.5"
          ></rect>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23.5 23.3C23.5 18.8196 23.5 16.5794 24.3719 14.8681C25.1389 13.3628 26.3628 12.1389 27.8681 11.3719C29.5794 10.5 31.8196 10.5 36.3 10.5H46.2C50.6804 10.5 52.9206 10.5 54.6319 11.3719C56.1372 12.1389 57.3611 13.3628 58.1281 14.8681C59 16.5794 59 18.8196 59 23.3V25.7C59 30.1804 59 32.4206 58.1281 34.1319C57.3611 35.6372 56.1372 36.8611 54.6319 37.6281C52.9206 38.5 50.6804 38.5 46.2 38.5H36.3C31.8196 38.5 29.5794 38.5 27.8681 37.6281C26.3628 36.8611 25.1389 35.6372 24.3719 34.1319C23.5 32.4206 23.5 30.1804 23.5 25.7V23.3Z"
            fill="#FA2D6C"
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16 23.3C16 18.8196 16 16.5794 16.8719 14.8681C17.6389 13.3628 18.8628 12.1389 20.3681 11.3719C22.0794 10.5 24.3196 10.5 28.8 10.5H38.7C43.1804 10.5 45.4206 10.5 47.1319 11.3719C48.6372 12.1389 49.8611 13.3628 50.6281 14.8681C51.5 16.5794 51.5 18.8196 51.5 23.3V25.7C51.5 30.1804 51.5 32.4206 50.6281 34.1319C49.8611 35.6372 48.6372 36.8611 47.1319 37.6281C45.4206 38.5 43.1804 38.5 38.7 38.5H28.8C24.3196 38.5 22.0794 38.5 20.3681 37.6281C18.8628 36.8611 17.6389 35.6372 16.8719 34.1319C16 32.4206 16 30.1804 16 25.7V23.3Z"
            fill="#20D5EC"
          ></path>
          <rect
            x="19.5"
            y="10.5"
            width="36"
            height="28"
            rx="8"
            fill="white"
          ></rect>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M36.5 18.25C36.2239 18.25 36 18.4739 36 18.75V23.5H31.25C30.9739 23.5 30.75 23.7239 30.75 24V25.5C30.75 25.7761 30.9739 26 31.25 26H36V30.75C36 31.0261 36.2239 31.25 36.5 31.25H38C38.2761 31.25 38.5 31.0261 38.5 30.75V26H43.25C43.5261 26 43.75 25.7761 43.75 25.5V24C43.75 23.7239 43.5261 23.5 43.25 23.5H38.5V18.75C38.5 18.4739 38.2761 18.25 38 18.25H36.5Z"
            fill="#161823"
          ></path></svg
      ></b-col>
      <b-col class="text-center"
        ><svg
          class="footer-icon"
          width="40"
          height="40"
          viewBox="0 0 32 32"
          fill="#fff"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.0362 21.3333H18.5243L15.9983 24.4208L13.4721 21.3333H7.96047L7.99557 8H24.0009L24.0362 21.3333ZM24.3705 23.3333H19.4721L17.2883 26.0026C16.6215 26.8176 15.3753 26.8176 14.7084 26.0026L12.5243 23.3333H7.62626C6.70407 23.3333 5.95717 22.5845 5.9596 21.6623L5.99646 7.66228C5.99887 6.74352 6.74435 6 7.66312 6H24.3333C25.2521 6 25.9975 6.7435 26 7.66224L26.0371 21.6622C26.0396 22.5844 25.2927 23.3333 24.3705 23.3333ZM12.6647 14C12.2965 14 11.998 14.2985 11.998 14.6667V15.3333C11.998 15.7015 12.2965 16 12.6647 16H19.3313C19.6995 16 19.998 15.7015 19.998 15.3333V14.6667C19.998 14.2985 19.6995 14 19.3313 14H12.6647Z"
          ></path>
        </svg>
        <div class="footer-text">{{ $t("page.tiktok.inbox") }}</div>
      </b-col>
      <b-col class="text-center"
        ><svg
          class="footer-icon"
          width="35"
          height="35"
          viewBox="0 0 48 48"
          fill="#fff"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.0001 11.5C20.9625 11.5 18.5001 13.9624 18.5001 17C18.5001 20.0376 20.9625 22.5 24.0001 22.5C27.0377 22.5 29.5001 20.0376 29.5001 17C29.5001 13.9624 27.0377 11.5 24.0001 11.5ZM15.5001 17C15.5001 12.3056 19.3057 8.5 24.0001 8.5C28.6945 8.5 32.5001 12.3056 32.5001 17C32.5001 21.6944 28.6945 25.5 24.0001 25.5C19.3057 25.5 15.5001 21.6944 15.5001 17ZM24.0001 30.5C19.1458 30.5 15.0586 33.7954 13.8578 38.2712C13.7147 38.8046 13.2038 39.1741 12.6591 39.0827L11.6729 38.9173C11.1282 38.8259 10.7571 38.3085 10.8888 37.7722C12.3362 31.8748 17.6559 27.5 24.0001 27.5C30.3443 27.5 35.664 31.8748 37.1114 37.7722C37.2431 38.3085 36.872 38.8259 36.3273 38.9173L35.3411 39.0827C34.7964 39.1741 34.2855 38.8046 34.1424 38.2712C32.9416 33.7954 28.8544 30.5 24.0001 30.5Z"
          ></path>
        </svg>
        <div class="footer-text">{{ $t("page.tiktok.profile") }}</div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer-icon {
  height: 30px;
}
.footer-text {
  font-size: 10px;
}
</style>
