<template>
  <div class="post-container h-100" >
    <!-- <img class="video-img" :src="this.videoImg" v-if="!isStimulus" /> -->
    <VideoPlayer
      :isStimulus="isStimulus"
      :src="videoSrc"
      :postId="postId"
      :currentSlideId="currentSlideId"
      @dblclick="toggleIsLiked"
    />
    <div class="sidebar-container text-center">
      <div class="poster">
        <img :src="posterImg" class="poster-image" width="44px" alt="" />
        <span class="poster-follow" v-if="!isStimulus"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60 60"
            width="60"
            height="60"
            preserveAspectRatio="xMidYMid slice"
            style="
              width: 100%;
              height: 100%;
              transform: translate3d(0px, 0px, 0px);
            "
          >
            <defs>
              <clipPath id="__lottie_element_2">
                <rect width="60" height="60" x="0" y="0"></rect>
              </clipPath>
            </defs>
            <g clip-path="url(#__lottie_element_2)">
              <g
                transform="matrix(1,0,0,1,30,30)"
                opacity="1"
                style="display: block"
              >
                <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                  <path
                    fill="rgb(255,43,84)"
                    fill-opacity="1"
                    d=" M24,0 C24,13.255000114440918 13.255000114440918,24 0,24 C-13.255000114440918,24 -24,13.255000114440918 -24,0 C-24,-13.255000114440918 -13.255000114440918,-24 0,-24 C13.255000114440918,-24 24,-13.255000114440918 24,0z"
                  ></path>
                  <path
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    fill-opacity="0"
                    stroke-miterlimit="4"
                    stroke="rgb(255,43,84)"
                    stroke-opacity="1"
                    stroke-width="0"
                    d=" M24,0 C24,13.255000114440918 13.255000114440918,24 0,24 C-13.255000114440918,24 -24,13.255000114440918 -24,0 C-24,-13.255000114440918 -13.255000114440918,-24 0,-24 C13.255000114440918,-24 24,-13.255000114440918 24,0z"
                  ></path>
                </g>
              </g>
              <g
                transform="matrix(1,0,0,1,30,30.125)"
                opacity="1"
                style="display: block"
              >
                <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                  <path
                    fill="rgb(255,43,84)"
                    fill-opacity="1"
                    d=" M0,-9 C0,-9 0,9 0,9"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="miter"
                    fill-opacity="0"
                    stroke-miterlimit="4"
                    stroke="rgb(255,255,255)"
                    stroke-opacity="1"
                    stroke-width="4"
                    d=" M0,-9 C0,-9 0,9 0,9"
                  ></path>
                </g>
              </g>
              <g style="display: none">
                <g>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="miter"
                    fill-opacity="0"
                    stroke-miterlimit="4"
                  ></path>
                </g>
              </g>
              <g
                transform="matrix(0,1,-1,0,30,30.125)"
                opacity="1"
                style="display: block"
              >
                <g
                  opacity="1"
                  transform="matrix(0.9297400116920471,0,0,1.0041899681091309,0,0)"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="miter"
                    fill-opacity="0"
                    stroke-miterlimit="4"
                    stroke="rgb(255,255,255)"
                    stroke-opacity="1"
                    stroke-width="4"
                    d=" M-0.125,-9 C-0.125,-9 -0.125,9 -0.125,9"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    fill-opacity="0"
                    stroke="rgb(255,255,255)"
                    stroke-opacity="1"
                    stroke-width="4"
                    d=" M-0.125,-9 C-0.125,-9 -0.125,9 -0.125,9"
                  ></path>
                </g>
                <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                  <path
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    fill-opacity="0"
                    stroke-miterlimit="4"
                    stroke="rgb(255,255,255)"
                    stroke-opacity="1"
                    stroke-width="4"
                    d=" M0.06199999898672104,0.06199999898672104"
                  ></path>
                </g>
              </g>
            </g></svg>
          </span>
      </div>
      <div class="like-icon" @click="toggleIsLiked">
        <span>
          <svg
            width="50"
            height="50"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <g>
                <path
                  :fill="isLiked?'red':'currentColor'"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.5 11.75C25.5 11.75 28 15.0833 28 15.0833C28 15.0833 30.5 11.75 35.5 11.75C41.3333 11.75 45.5 16.3333 45.5 22.1667C45.5 28.8333 40.0518 34.7762 35.0833 39.25C32.0159 42.012 29.6667 43.8333 28 43.8333C26.3333 43.8333 23.9175 41.9982 20.9167 39.25C16.0325 34.7771 10.5 28.8333 10.5 22.1667C10.5 16.3333 14.6667 11.75 20.5 11.75Z"
                ></path>
              </g>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.003 28.3329C13.9817 32.4943 17.5987 36.2114 20.9165 39.2499C23.9173 41.9981 26.3332 43.8332 27.9998 43.8332C29.6665 43.8332 32.0157 42.0119 35.0832 39.2499C40.0517 34.7761 45.4998 28.8332 45.4998 22.1666C45.4998 22.0209 45.4972 21.8761 45.4921 21.7321C42.2785 30.7698 30.7978 38.8333 27.5834 38.8333C25.1879 38.8333 17.3515 34.3551 12.003 28.3329Z"
                fill="black"
                fill-opacity="0.12"
              ></path>
            </g>
          </svg>
        </span>
        {{ this.likes }}
      </div>
      <div class="comment-icon">
        <span>
          <svg
            width="35"
            height="35"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.9">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M38.4943 35.3128C42.6 31.2 45 26.9162 45 21.928C45 11.8056 35.733 3.60001 24.2999 3.60001C12.8671 3.60001 3.6 11.8056 3.6 21.9283C3.6 32.051 13.1669 39 24.6 39V42.3569C24.6 43.4205 25.7028 44.105 26.638 43.5983C29.5598 42.015 34.9741 38.8392 38.4943 35.3128ZM14.2446 19.4564C15.8786 19.4564 17.2031 20.7714 17.2031 22.3912C17.2031 24.0142 15.8786 25.3291 14.2446 25.3291C12.6134 25.3291 11.2888 24.0142 11.2888 22.3912C11.2888 20.7714 12.6134 19.4564 14.2446 19.4564ZM27.2572 22.3912C27.2572 20.7714 25.9332 19.4564 24.3 19.4564C22.667 19.4564 21.3429 20.7714 21.3429 22.3912C21.343 24.0142 22.6671 25.3291 24.3 25.3291C25.9332 25.3291 27.2572 24.0142 27.2572 22.3912ZM34.355 19.4564C35.9887 19.4564 37.3113 20.7714 37.3113 22.3912C37.3113 24.0142 35.9888 25.3291 34.355 25.3291C32.7213 25.3291 31.3969 24.0142 31.397 22.3912C31.397 20.7714 32.7214 19.4564 34.355 19.4564Z"
                fill="white"
              ></path>
            </g>
            <path
              opacity="0.1"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M24.6001 38.9999C24.6001 38.9999 36.0669 38.1138 40.8601 31.9764C36.0669 38.7276 31.2737 42.4101 27.0796 43.6376C22.8855 44.8651 24.6001 38.9999 24.6001 38.9999Z"
            ></path>
          </svg>
        </span>
        {{ this.comments }}
      </div>
      <div class="share-icon">
        <span>
          <svg
            width="35"
            height="35"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.9">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.8 10.3085C25.8 9.04248 27.3162 8.39262 28.233 9.26572L42.1847 22.5524C43.4124 23.7216 43.3695 25.6932 42.092 26.8079L28.1867 38.9414C27.2552 39.7542 25.8 39.0926 25.8 37.8564V32.3581C25.8 32.3581 10.8695 29.6685 6.08025 38.4593C5.63374 39.2789 3.89328 39.5657 4.24706 35.4764C5.72648 27.9499 8.75001 16.1999 25.8 16.1999V10.3085Z"
                fill="white"
              ></path>
            </g>
            <path
              opacity="0.03"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M36.0958 16.8L38.8639 22.3362C39.3536 23.3155 39.1184 24.5021 38.2921 25.2206L25.8958 36C25.8958 36 25.2958 39 27.0958 39C28.8958 39 43.2958 25.8 43.2958 25.8C43.2958 25.8 43.8958 24 42.0958 22.2C40.2958 20.4 36.0958 16.8 36.0958 16.8Z"
              fill="#161823"
            ></path>
            <path
              opacity="0.09"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M25.7997 16.8389V32.4389C25.7997 32.4389 11.5114 30.4255 7.03635 37.2389C2.73042 43.7949 3.12588 29.8349 9.60816 22.8829C16.0904 15.931 25.7997 16.8389 25.7997 16.8389Z"
              fill="url(#ShareShadowColor_paint0_radial)"
            ></path>
          </svg>
        </span>
        {{ this.shares }}
      </div>
      <div class="song-author">
        <img
          v-if="!isStimulus"
          :src="posterImg"
          class="song-author-image"
          alt="song-author-image"
        />
      </div>
    </div>
    <div class="bottom-container">
      <div class="bottom-container-inner">
        <div class="poster-name">{{ posterName }}</div>
        <div class="text">
          <!-- <div v-for="(row, index) in text" :key="index">{{ row }}</div> -->
          <div>{{ text }}</div>
        </div>
        <div class="sponsored" v-if="isStimulus">Ad</div>
        <div class="song-info" v-else>
          <div class="music-icon"></div>
          <div class="song-title-wrapper">
            <div class="song-title-inner">
              <span class="song-title">{{ songTitle }}</span>
              <span class="song-title">{{ songTitle }}</span>
              <span class="song-title">{{ songTitle }}</span>
              <span class="song-title">{{ songTitle }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "./VideoPlayer.vue";

import { mapGetters } from "vuex";

export default {
  props: ["post", "currentSlideId"],
  components: { VideoPlayer },
  computed: {
    ...mapGetters("stimulus", {
      stimulus: "getStimulus",
    }),
    postId() {
      return this.$vnode.key;
    },
    isStimulus() {
      return this.post.isStimulus || false;
    },
    posterImg() {
      if (this.isStimulus) {
        return this.post.bodyParsed.header?.leftColumn?.imageUrl;
      } else {
        return this.post.pictures.find((string) =>
          string.includes("headerImg")
        )||this.post.pictures.find((string)=>string.includes("profilePic"));
      }
    },
    videoImg() {
      return this.post.pictures.find((string) => string.includes("videoImg"))||"";
    },
    isLiked(){
      return this.post.isLiked
    },
    posterName() {
      return (
        this.post.bodyParsed.header?.rightColumn?.bottomRow?.posterName || this.post.bodyParsed.username || "author"
      );
    },
    likes() {
      return (
        this.post.bodyParsed?.footer?.reactionsCounter || this.formatNumber(this.post.bodyParsed.likeCount) ||
        Math.floor(Math.random() * 1000)
      );
    },
    comments() {
      return (
        this.post.bodyParsed.footer?.commentsCounter || this.formatNumber(this.post.bodyParsed.commentCount) ||
        Math.floor(Math.random() * 1000)
      );
    },
    shares() {
      return (
        this.post.bodyParsed.footer?.sharesCounter || this.formatNumber(this.post.bodyParsed.shareCount) ||
        Math.floor(Math.random() * 1000)
      );
    },
    videoSrc() {
      if (this.isStimulus) {
        return this.stimulus[0].src;
      } else {
        return this.post.pictures.find((string) =>
          string.includes("videoStream")
        )||this.post.pictures.find((string)=>
          string.includes("video")
        );
      }
    },
    text() {
      if (this.isStimulus) {
        return this.post.bodyParsed.body?.topRow?.text;
      } else {
        const text = this.post.bodyParsed.body?.topRow?.text.slice(0, -1) || this.post.bodyParsed.description;
        return text;
      }
    },
    songTitle() {
      const text = this.post.bodyParsed.body?.topRow?.text.slice(-1)[0]|| `${ this.post.bodyParsed.music|| 'original sound'}  - ${this.post.bodyParsed.musicAuthor || this.post.bodyParsed.username}`;
      return text;
    },
  },
  methods: {
    formatNumber(number) {
      if(typeof number !=='number' || number === 0)return false
      if (number >= 1000000) return (number / 1000000).toFixed(1) + 'M';
      if (number >= 1000) return (number / 1000).toFixed(1) + 'K';
      return number.toLocaleString();
    },
    toggleIsLiked(){
      this.$store.commit("tiktok/SET_POST_IS_LIKED",this.postId)
      if(this.isStimulus)
        this.$store.commit("stimulus/SET_STIMULUS_LIKED");
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-container {
  font-size: 13px;
  font-weight: 700;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 2;
  position: absolute;
  top: 0px;
  right: 0px;
  padding-right: 10px;
  padding-bottom: 15px;
  .poster {
    position: relative;
    .poster-image {
      border-radius: 50%;
      border: 1px solid white;
    }
    .poster-follow {
      position: absolute;
      bottom: -12px;
      display: block;
      right: 0px;
      left: 0px;
      margin: auto;
      width: 22px;
    }
  }
  .like-icon {
    margin-top: 20px;
  }
  .comment-icon {
    margin-top: 20px;
  }
  .share-icon {
    margin-top: 20px;
  }
  .song-author {
    height: 40px;
    width: 40px;
    .song-author-image {
      transform: rotate(0deg);
      animation: 5s linear 0s infinite normal none running rotation;
      margin: 0px;
      border-radius: 50%;
      width: 40px;
    }
  }
  .song-author {
    margin-top: 20px;
  }
}

.bottom-container {
  position: absolute;
  bottom: 0px;
  padding: 10px 0px 10px 10px;
  width: 100%;
  z-index: 1;
  transform: translateZ(0px);
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  .bottom-container-inner {
    width: 77%;
    .poster-name {
      font-size: 17px;
      margin-bottom: 7px;
      font-weight: 600;
    }
    .text {
      font-size: 15px;
      margin-bottom: 10px;
      max-height: 45px;
      max-height: 45px;
      overflow: hidden;
    }
    .song-info {
      font-size: 15px;
      position: relative;
      .music-icon {
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6QAAArtJREFUaAXtmj9oFEEUxu8SoxBUohAlQSNiCv9UJiBWVqawEbuAoIWiYCFYpUolgo2VTRori9Q2tqJNtBNETTBeSiGBdAFjwKy/L9yeG3b2Zv/MzkWZB9/N3cy7977vvd25Y+4ajf/Emv+ijiiKxuB9Cqw2m81v0rCnhUD4JBwvJHCe58IhENsLxNzbE0LaFRbBJOlzvE4Sjombxmv7TLN1zEFWRYsrHJM2VbhM+knnQjIIq9JFKlxUzEBlIRAfJus0mAAirCofBF6tkhBEaOd4D0ZqZr1N/BXwBUyBQbDLKgkh0iPgUkSS8Fdii7iwxM60ydigeD8YnAs5o+AlLElYRGPSHcJFY1btiG37FuFWgmhMujThLIFVhZjiLjL5pE3eOWFTQs3VIaTF9TyflbCu+b66AvuOG4T4rrgtX+iIrUK+10NHfFfcli90xFYh3+uhI74rbssXOmKrkO/10BHfFbflCx2xVcj3euiI74rb8oWO2Crke72OU5TCGjg9PMGb7gIddI8DnV5mnZkdZy1lPRXSPgB/CqtbYH+KXYGJnglBxDF4LoCyx667ZKaEkOAwHnfARaA2j4F+YLKjpsmcc3P4ORGhfB0hCFBrZ8FDMARqM3LpHrjhMsGOEAJrfA2uugzeJdZZ1lxu/VEcbMajCOnb0INDW42FPHAYNE+oTzit53HM6fO2r31zax93ZZEtEKf1v/B5bPPLuf6SeEvqyE+gH2Rc2VqeQCR/jt+zPL5dfHRf/72a6Mo74MpudkmcWiLpJfAKbBcg8AHf66Dz6b/zhIkrZHgDsj4vUgQyJj4yf5lqb2WsZ07DYZRF7WbjwPQVRffUd7BMfI1mI9BtsAXK2mfeqH829N4gchrMgU2Q11o43gcHeqmgc40lSUDqCK/1Dwa12fQVRZ8D+rV2GSzS6t+MwVxU4A+ZcZNXUfQErAAAAABJRU5ErkJggg==");
        background-size: 13px 13px;
        background-repeat: no-repeat;
        background-position: left center;
        width: 15px;
        height: 15px;
      }
      .song-title-wrapper {
        position: relative;
        margin-inline-start: 32px;
        font-size: 15px;
        overflow: hidden;
      }
      .song-title-inner {
        margin-left: -17px;
        margin-right: 0px;
        display: flex;
        width: max-content;
        animation: 3.5s linear 0s infinite normal none running marquee;

        .song-title {
          padding-inline-start: 20px;
        }
      }
    }
    .sponsored {
      font-size: 10px;
      padding: 2px 5px;
      background-color: rgba(255, 255, 255, 0.2);
      width: fit-content;
      border-radius: 2px;
    }
  }
}

// .video-img {
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   filter: blur(20px);
//   overflow: hidden;
//   transform: translate3d(0, 0, 0);
// }

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0px, 0px);
  }
  100% {
    transform: translate(-25%, 0px);
  }
}
</style>
