<template>
  <div class="h-100">
    <video-player
      class="vjs-tiktok"
      ref="videoPlayer"
      :playsinline="true"
      :options="playerOptions"
      @playing="handlePlaying"
      @pause="handlePause"
      @ended="handleEnded"
      @timeupdate="handleTimeUpdate"
      @loadeddata="setStimulusDuration"
      @error="playbackError"
      v-touch:tap="togglePlayback"
    >
    </video-player>
    <div
      class="play-btn"
      v-if="showPlayButton"
      v-touch:tap="togglePlayback"
    ></div>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import { mapGetters, mapMutations } from "vuex";

export default {
  props: ["postId", "currentSlideId", "isStimulus", "src"],
  components: {
    videoPlayer,
  },
  data() {
    return {
      previousPosition: 0,
      showPlayButton: false,
      replay: 1,
      initialTimestamp: Date.now(),
      findNextSecondStartInterval: null,
    };
  },
  watch: {
    currentSlideId: {
      handler(newValue, oldValue) {
        if (this.surveyStep === "scroll") {
          if (newValue === this.postId) {
            // start current video playback
            this.player.muted(false);
            this.player.play();
            this.SET_POST_IS_VIEWED(this.postId);
          } else if (oldValue === this.postId) {
            // stop previous video playback + reset video currentTime
            this.player.pause();
            this.player.currentTime(0);
            this.showPlayButton = false;
          }
        }
      },
    },
  },
  mounted() {
    this.player.muted(false);
    if (this.postId === 0 && this.surveyStep === "scroll") {
      this.SET_POST_IS_VIEWED(this.postId);
    }
  },
  beforeDestroy() {
    this.surveyStepFinished();
  },
  computed: {
    ...mapGetters("stimulus", {
      stimulus: "getStimulus",
    }),
    ...mapGetters("tiktok", {
      surveyStep: "getSurveyStep",
    }),
    playerOptions() {
      return {
        controls: false,
        fluid: true,
        preload: "auto",
        autoplay: this.postId === 0,
        loop: this.surveyStep === "scroll",
        muted: true,
        errorDisplay: false,
        sources: [
          {
            src: this.src,
            type: "video/mp4",
          },
        ],
        poster: "",
      };
    },
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    ...mapMutations("tiktok", [
      "SET_SURVEY_STEP_DURATION",
      "SET_POST_IS_VIEWED",
      "SET_SHOW_PROMPT",
      "SET_SURVEY_STEP",
    ]),
    startRecording({ position, replay, timestamp }) {
      this.$parent.$parent.$parent.$emit("startRecording", {
        stimulus: this.stimulus[0],
        position,
        replay,
        timestamp,
        phase: this.surveyStep === "scroll" ? 1 : 2,
      });
    },
    stopRecording() {
      this.$parent.$parent.$parent.$emit("stopRecording", {
        stimulus: this.stimulus[0],
      });
    },
    togglePlayback() {
      if (this.surveyStep === "scroll") {
        if (this.player.paused()) {
          this.showPlayButton = false;
          this.player.play();
        } else {
          this.showPlayButton = true;
          this.player.pause();
        }
      }
    },
    positionRounded() {
      return Math.round(this.player.currentTime());
    },
    handlePlaying() {
      if (this.isStimulus) {
        if (this.findNextSecondStartInterval) {
          clearInterval(this.findNextSecondStartInterval);
        }
        this.findNextSecondStartInterval = setInterval(() => {
          if (this.checkFirstDecimal(this.player.currentTime())) {
            this.startRecording({
              position: this.positionRounded(),
              replay: this.replay,
              timestamp: Date.now() - this.initialTimestamp,
              phase: this.surveyStep === "scroll" ? 1 : 2,
            });
            clearInterval(this.findNextSecondStartInterval);
          }
        }, 10);
      }
    },
    checkFirstDecimal(number) {
      const decimalPart = number % 1;
      const decimalString = decimalPart.toString();
      // check millisecond decimal
      if (decimalString[2] === "0" || decimalString[2] === "1") {
        return true;
      } else {
        return false;
      }
    },
    handlePause() {
      if (this.isStimulus) this.stopRecording();
      if (this.findNextSecondStartInterval) {
        clearInterval(this.findNextSecondStartInterval);
      }
      if (this.positionRounded() === 0) {
        this.replay++;
      }
    },
    handleEnded() {
      if (this.isStimulus) {
        this.SET_SURVEY_STEP("finish");
        this.SET_SHOW_PROMPT(true);
      }
    },
    surveyStepFinished() {
      this.handlePause();
    },
    handleTimeUpdate() {
      if (
        this.isStimulus &&
        this.surveyStep === "scroll" &&
        this.postId === this.currentSlideId &&
        this.previousPosition > this.positionRounded()
      ) {
        this.stopRecording();
        this.replay++;
        this.startRecording({
          position: this.positionRounded(),
          replay: this.replay,
          timestamp: Date.now() - this.initialTimestamp,
          phase: this.surveyStep === "scroll" ? 1 : 2,
        });
        this.previousPosition = this.positionRounded();
      } else {
        this.previousPosition = this.positionRounded();
      }
    },
    setStimulusDuration() {
      if (this.isStimulus) {
        this.SET_SURVEY_STEP_DURATION(Math.floor(this.player.duration()));
      }
    },
    playbackError(err) {
      console.log("Video Playback Error: ", err);
    },
  },
};
</script>

<style lang="scss">
.vjs-tiktok {
  height: 100%;
  background-color: transparent;
  .video-js {
    background-color: transparent;
    height: 100%;
    padding-top: 0px;
    .vjs-tech {
      height: 100%;
      object-fit: contain;
    }
  }
}

.play-btn {
  background-image: url("../../../assets/img/TikTok/play.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 62px;
  height: 62px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>
